import { theme } from './theme';
import { hexOpacity } from './utils/hexOpacity';

const border = `1px solid ${hexOpacity(theme.palette.secondary.main, '14%')}`;
const borderBox = 'border-box';
const scrollBar = {
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: hexOpacity(theme.palette.secondary.main, '14%'),
  },
};

const TAKE_PAGER_CHATS = 15;
const TAKE_PAGER_MESSAGE = 20;

const TAKE_PAGER_OPTIONS = 20;

const MAX_MOBILE_WIDTH = 599;
const MAX_TABLET_WIDTH = 1199;

const CLINICAL_TASK_PERMISSION = 'messages.clinical.tasks';
const ORDERS_CHANGING_PERMISSION = 'orders.changing';
const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export {
  border,
  borderBox,
  scrollBar,
  TAKE_PAGER_CHATS,
  TAKE_PAGER_MESSAGE,
  TAKE_PAGER_OPTIONS,
  MAX_MOBILE_WIDTH,
  MAX_TABLET_WIDTH,
  CLINICAL_TASK_PERMISSION,
  ORDERS_CHANGING_PERMISSION,
  GUID_EMPTY,
};
