import React, { useContext, useState } from 'react';
import { Box, Button, CircularProgress, List, ListItem, Stack, Typography } from '@mui/material';
import { TMessageItem } from 'src/api/messages/models';
import { PortalContext } from 'src/context/portal';
import { TaskMessage, TaskMessageInline } from '../Task.styled';
import { prepareOrderLink } from './helpers';
import styled from '@emotion/styled';
import { theme } from 'src/theme';
import { FastingVisible } from 'src/api/assignments/models';
import { getDocumentById } from 'src/api/documents';
import { ReactComponent as PrintIcon } from 'src/icons/18x18/print-icon.svg';
import { GUID_EMPTY } from 'src/const';

type AddOnTestsProps = {
  task: TMessageItem;
};

const classes = {
  list: {
    listStyleType: 'none',
    padding: '0',
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '0px',
    lineHeight: '24px',
    fontSize: '14px',
  },
};

const Marker = styled.div`
  height: 3px;
  width: 3px;
  background: ${theme.palette.grey[700]};
  border-radius: 50%;
`;

export const AddOnTests: React.FC<AddOnTestsProps> = ({ task }) => {
  const {
    originalOrderNumber,
    tests,
    primaryDiagnosis,
    secondariesDiagnoses,
    orderingProvider,
    billType,
    body,
    fasting,
    addonTestRequestDocumentId,
  } = task;
  const { orderLinkTemplate } = useContext(PortalContext);

  const [isLoadingBlank, setIsBlankLoading] = useState(false);
  const [loadBlankIsError, setLoadBlankIsError] = useState(false);

  const pathForOrderByNumber = prepareOrderLink(orderLinkTemplate, originalOrderNumber);

  const generateBlank = async () => {
    if (addonTestRequestDocumentId) {
      try {
        setIsBlankLoading(true);
        const fileBody = await getDocumentById(addonTestRequestDocumentId);
        if (fileBody.data) {
          setLoadBlankIsError(false);
          setIsBlankLoading(false);
          const blob = new Blob([fileBody.data], { type: 'application/pdf' });
          const blobURL = URL.createObjectURL(blob);
          setTimeout(() => {
            const aElement = document.createElement('a');
            aElement.href = blobURL;
            aElement.setAttribute('target', '_blank');
            aElement.click();
          }, 100);
        } else {
          setLoadBlankIsError(true);
        }
      } catch {
        setLoadBlankIsError(true);
        setIsBlankLoading(false);
      }
      setIsBlankLoading(false);
    }
  };

  return (
    <>
      <TaskMessage
        fontSize="16px"
        inline={1}
        prewrap={1}
        fontWeight={700}
        sx={{ marginBottom: '12px', marginRight: '6px' }}
      >
        {' for order'}
      </TaskMessage>
      <a rel="noreferrer" href={pathForOrderByNumber} target="_blank">
        <Typography component="span" fontWeight={500} fontSize="14px" color={theme.palette.secondary.main}>
          {`#${originalOrderNumber} `}
        </Typography>
      </a>
      <Stack gap="12px">
        <Stack gap="6px">
          <TaskMessage fontWeight={700} color="grey.700">
            Tests
          </TaskMessage>
          <List sx={classes.list}>
            {tests?.map(test => (
              <ListItem key={test} sx={classes.li}>
                <Marker />
                {test}
              </ListItem>
            ))}
          </List>
        </Stack>
        <Stack direction="row" gap="12px" alignItems="center">
          <TaskMessage fontWeight={700} color="grey.700">
            Fasting
          </TaskMessage>
          <Typography variant="label14Medium" color="grey.700">
            {fasting ? FastingVisible[fasting] : '-'}
          </Typography>
        </Stack>

        <TaskMessageInline fontWeight={700}>
          {'Primary diagnosis '}
          <TaskMessage inline={1} sx={{ display: 'inline' }}>
            {primaryDiagnosis ?? ''}
          </TaskMessage>
        </TaskMessageInline>
        {secondariesDiagnoses && secondariesDiagnoses.length > 0 && (
          <Stack gap="6px">
            <TaskMessage fontWeight={700}>Secondary diagnoses</TaskMessage>
            <List sx={classes.list}>
              {secondariesDiagnoses.map(diagnosis => (
                <ListItem sx={classes.li} key={diagnosis}>
                  <Marker />
                  {diagnosis}
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
        <TaskMessageInline fontWeight={700}>
          {'Ordering Provider '}
          <TaskMessage inline={1}>{orderingProvider}</TaskMessage>
        </TaskMessageInline>
        <TaskMessageInline fontWeight={700}>
          {'Billing Type '}
          <TaskMessage inline={1}>{billType}</TaskMessage>
        </TaskMessageInline>
        {body && (
          <TaskMessageInline fontWeight={700} mb="12px">
            {'Message '}
            <TaskMessage inline={1}>{body}</TaskMessage>
          </TaskMessageInline>
        )}
        {addonTestRequestDocumentId !== GUID_EMPTY && (
          <Box display="inline-block" mt="6px" mb="6px">
            <Button
              startIcon={
                isLoadingBlank ? (
                  <CircularProgress
                    size="18px"
                    sx={themeLocal => ({
                      flexGrow: 0,
                      color: themeLocal.palette.grey['300'],
                    })}
                  />
                ) : (
                  <PrintIcon />
                )
              }
              variant="text"
              color="secondary"
              sx={{ padding: '0', height: 'auto', lineHeight: '18px' }}
              fullWidth={false}
              disabled={isLoadingBlank}
              onClick={generateBlank}
            >
              Add-On Tests Request Document
            </Button>
            {loadBlankIsError && (
              <Box mt="6px">
                <Typography color="errorInput.main" variant="label12Medium" lineHeight="18px">
                  Something went wrong. Try again later.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Stack>
    </>
  );
};
